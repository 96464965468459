<template>
    <el-main>
        <el-form label-width="200px">
            <el-tabs v-model="tab_active">
                <el-tab-pane v-for="item in data" :key="item.name" :name="item.name" :label="item.label">
                    <el-form-item :label="item.label + '：'" required v-if="tab_active == item.name">
                        <RichText @getContent="(val, name) => getContent(val, name)" :richTxt="item.content"
                            :editorId="'RichText' + item.name">
                        </RichText>
                    </el-form-item>
                    <el-form-item label="联系人姓名：" required v-if="tab_active < 4">
                        <el-input v-model="item.contact_name"></el-input>
                    </el-form-item>
                    <el-form-item label="联系电话：" required v-if="tab_active < 4">
                        <el-input v-model="item.contact_mobile"></el-input>
                    </el-form-item>
                </el-tab-pane>
            </el-tabs>
        </el-form>
        <Preservation @preservation="save"></Preservation>
    </el-main>
</template>

<script>
import Preservation from '@/components/preservation';
import RichText from '@/components/richText';
export default {
    components: {
        Preservation,
        RichText
    },
    data () {
        return {
            tab_active: '1',//1技工入驻海报，2商家入驻海报，3合伙人入驻海报4--用户协议 5--商户协议
            data: [
                {
                    name: '1',
                    label: '技工招募海报内容',
                    content: '',
                    contact_name: '',
                    contact_mobile: ''
                },
                {
                    name: '2',
                    label: '商家招募海报内容',
                    content: '',
                    contact_name: '',
                    contact_mobile: ''
                },
                {
                    name: '3',
                    label: '合伙人招募海报内容',
                    content: '',
                    contact_name: '',
                    contact_mobile: ''
                },
                {
                    name: '4',
                    label: '用户协议',
                    content: ''
                },
                {
                    name: '5',
                    label: '商户协议',
                    content: ''
                },
                {
                    name: '6',
                    label: '平台协议',
                    content: ''
                },
                {
                    name: '7',
                    label: '关于我们',
                    content: ''
                },
                {
                    name: '8',
                    label: '城市合伙人海报',
                    content: ''
                }
            ]
        };
    },
    created () {
        this.getInfo()
    },
    methods: {
        getInfo () {
            this.$axios.post(this.$api.repair.shop.Posterinfo).then(res => {
                if (res.code == 0) {
                    let artisan = res.result['1']
                    let store = res.result['2']
                    let partner = res.result['3']
                    let user_content = res.result['4']
                    let store_content = res.result['5']
                    let platform_content = res.result['6']
                    let about_us = res.result['7']

                    this.data[0].content = artisan.content
                    this.data[0].contact_name = artisan.contact_name
                    this.data[0].contact_mobile = artisan.contact_mobile
                    this.data[1].content = store.content
                    this.data[1].contact_name = store.contact_name
                    this.data[1].contact_mobile = store.contact_mobile
                    this.data[2].content = partner.content
                    this.data[2].contact_name = partner.contact_name
                    this.data[2].contact_mobile = partner.contact_mobile
                    this.data[3].content = user_content.content
                    this.data[4].content = store_content.content
                    this.data[5].content = platform_content.content
                    this.data[6].content = about_us.content
                    this.data[7].content = res.result['8'].content
                } else {
                    this.$message.error(res.msg);
                }
            });
        },
        getContent (val1, val2) {
            let index = val2.slice(-1) - 1
            this.data[index].content = val1
        },
        save () {
            this.data.forEach(async item => {
                let form = {
                    content: item.content,
                    type: item.name
                }
                if (item.name < 4) {
                    form.contact_name = item.contact_name
                    form.contact_mobile = item.contact_mobile
                }
                let res = await this.$axios.post(this.$api.repair.shop.Posteredit, form)
                if (res.code == 0) {
                    this.$message.success(item.label + '保存成功');
                    if (item.name == 5) {
                        this.getInfo()
                    }
                } else {
                    this.$message.error(res.msg);
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background: #fff;
    min-height: 100%;
    padding-bottom: 60px;
    box-sizing: border-box;

    .fcccc {
        color: #ccc;
        border: 1px solid #ccc !important;
        cursor: no-drop !important;
    }

    .tips {
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 20px;
    }


}

.el-input {
    width: 300px;
}
</style>
